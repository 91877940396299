import React from 'react'
import Blocks from './blocks/Blocks'
import SEO from "../components/seo"
import Layout from "../components/layout"
import { StateProvider } from "../utils/StateProvider"
import { initialState, reducer } from "../utils/reducer"
/* import ArticleSlider from '../components/ArticleSlider' */
import ValueMenu from "../components/ValueMenu"
import TitleBlock from './blocks/TitleBlock'

export default function simplePage(props) {
    const { pageContext, location } = props
    const lang = pageContext.page.locale ? pageContext.page.locale.toLowerCase() : null

    let articles = []
    if (lang === "fr") {
        articles = pageContext.articles.fr.slice(0, 3)
    } else if (lang === "en") {
        articles = pageContext.articles.en.slice(0, 3)
    }

    return (
        <StateProvider initialState={initialState} reducer={reducer}>
            <Layout location={location}>
                <SEO
                    description={pageContext.page.description}
                    title={pageContext.page.titleSEO}
                    lang={lang}
                />
                <div className="simple-page">
                    {pageContext.page.blocks[0].__typename === "jhipster_CustomBlockDTO" && pageContext.page.blocks[0].subType === "TitleBlock" ? (
                        <>
                            <TitleBlock data={pageContext.page.blocks[0].values} media={pageContext.media} />
                            <div className="frame">
                                <Blocks blocks={pageContext.page.blocks.slice(1)} media={pageContext.media} lang={lang} />
                                {/* {pageContext.page.url === "/" && <ArticleSlider articles={articles} media={pageContext.media} lang={lang} />} */}
                                {pageContext.page.url === "/valeurs-et-missions" && <ValueMenu valueBlockIds={pageContext.page.blocks.filter(block => block.__typename === "jhipster_CustomBlockDTO" && block.subType === "ValueBlock").map(block => block.values.titre)} />}
                            </div>
                        </>
                    ) : (
                            <>
                                <Blocks blocks={pageContext.page.blocks} media={pageContext.media} lang={lang} />
                                {/* {pageContext.page.url === "/" && <ArticleSlider articles={articles} media={pageContext.media} lang={lang} />} */}
                                {pageContext.page.url === "/valeurs-et-missions" && <ValueMenu valueBlockIds={pageContext.page.blocks.filter(block => block.__typename === "jhipster_CustomBlockDTO" && block.subType === "ValueBlock").map(block => block.values.titre)} />}
                            </>
                        )}
                </div>
            </Layout>
        </StateProvider>
    )
}

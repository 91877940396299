import React from "react"
import { useStateValue } from "../utils/StateProvider"

function ValueMenu({ valueBlockIds }) {
  const { state } = useStateValue()
  return (
    <>
      {state.visibleValue && (
        <div className="value-menu">
          {valueBlockIds.map(id => (
            <a
              key={`value-menu-link-${id}`}
              href={`#${id}`}
              className={state.visibleValue === id ? "visible" : null}
            >
              {id}
            </a>
          ))}
        </div>
      )}
    </>
  )
}

export default ValueMenu
